import { LOGIN_SUCCESS, RESET_AUTH_STATE, UPDATE_ATTRIBUTES } from '../action.types';

const INITIAL_STATE = {
  isLoggedIn: false,
  userAtrributes: {
    username: '',
    name: '',
  },
  countryCode: '+91',
};

const authReducer =  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: 
      return {
        ...state,
        userAtrributes: {
          ...state.userAtrributes,
          ...action.payload,
        }, 
        isLoggedIn: true,
      }
    case UPDATE_ATTRIBUTES: 
      return {
        ...state, 
        userAtrributes: {
          ...state.userAtrributes,
          ...action.payload,
        },
      }
    case RESET_AUTH_STATE: 
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default authReducer;
