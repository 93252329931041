const awsconfig = {
    Auth: {
        identityPoolId: "ap-south-1:2229c40a-bc56-4b92-bb5b-6d84ddbbc148",
        identityPoolRegion: "ap-south-1",
        region: "ap-south-1",
        userPoolId: "ap-south-1_HPj42cFQa",
        userPoolWebClientId: "l9qccc5icdh8s3ibpnhaa5sq6",
        oauth: {
            domain: "auth.brahmavidya.in",
            scope: [
                "phone",
                "email",
                "profile",
                "openid",
                "aws.cognito.signin.user.admin",
            ],
            redirectSignIn: "https://brahmavidya.in",
            redirectSignOut: "https://brahmavidya.in/signin",
            responseType: "code",
        },
    }
};

export { awsconfig }