import React, { Component } from 'react';

import Amplify, { Auth } from 'aws-amplify';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
    Container,
    Grid,
    TextField,
    InputAdornment,
    MenuItem,
    FormControl,
    Typography,
    Button,
    Select,
    CssBaseline,
    Box,
    Avatar,
    InputLabel,
    Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { validateEmail, validateMobileNumber, containsCharacters, validatePassword } from '../../../helpers/functions';

class ConfirmAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            code: "",
            invalidPassword: false,
            requestSubmitted: false,
            passwordMismatch: false,
        };
    }

    changePassword = async () => {
        try {
            const { location } = this.props;
            const { state } = location;
            const { code, password, confirmPassword } = this.state;
            this.setState({
                requestSubmitted: true,
                invalidPassword: false,
                passwordMismatch: false,
            });
            if (validatePassword(password)) {
                if(password === confirmPassword) {

                    await Auth.forgotPasswordSubmit(state.username, code, password);
                    this.setState({ requestSubmitted: false });
                    this.props.history.push("SignIn");
                    this.props.enqueueSnackbar("Password Update Successful", { variant: 'success' });
                } else {
                    this.setState({ requestSubmitted: false, passwordMismatch: true });
                }
            } else {
                this.setState({ requestSubmitted: false, invalidPassword: true });
            }
        } catch (error) {
            try {
                console.log("error in updating password", error);
                let errorMsg = "";
                if (error.code === "CodeMismatchException") {
                    errorMsg = "Invalid Code";
                } else if (error.code === "TooManyRequestsException") {
                    errorMsg =
                        "Too many requests for verification code, Please try again after some time";
                } else {
                    throw new Error("Something went wrong");
                }
                this.props.enqueueSnackbar(errorMsg, { variant: 'error' });
            } catch (err) {
                this.props.enqueueSnackbar("Unable to Change password", { variant: 'error' });
            }
            this.setState({ requestSubmitted: false });
        }
    };

    confirmAccount = async () => {
        try {
            const { location } = this.props;
            const { state } = location;
            const { code } = this.state;
            this.setState({
                requestSubmitted: true,
            });
            await Auth.confirmSignUp(state.username, code);
            this.setState({ requestSubmitted: false });
            this.props.enqueueSnackbar("Account Verification Successful", { variant: 'success' });
            this.props.history.push("SignIn");
        } catch (error) {
            console.log("error in confirming sign up", error);
            try {
                let errorMsg = "";
                if (error.code === "CodeMismatchException") {
                    errorMsg = "Invalid Code";
                } else if (error.code === "CodeDeliveryFailureException") {
                    errorMsg = "Unable to send verification code";
                } else if (error.code === "TooManyRequestsException") {
                    errorMsg =
                        "Too many requests for verification code, Please try again after some time";
                } else {
                    throw new Error("Something went wrong");
                }
                this.props.enqueueSnackbar(errorMsg, { variant: 'error' });
            } catch (err) {
                this.props.enqueueSnackbar("Unable to Confirm Account", { variant: 'error' });
            }
            this.setState({ requestSubmitted: false });
        }
    };

    renderLogIn = () => {
        const { code, password, confirmPassword, invalidPassword, requestSubmitted, passwordMismatch } = this.state;

        const { location } = this.props;
        const { state } = location;

        const subTitle = state.isChangePassword ? "Change Password" : "Confirm Account";
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {subTitle}
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                        <Typography sx={{ mt: 2, mb: 2 }} textAlign="center">
                            We have sent a verification code to your Email Id. Enter it below to{' '}
                            {state.isChangePassword ? 'change password' : 'confirm account'}
                        </Typography>
                        <TextField
                            sx={{ mb: 2 }}
                            margin="normal"
                            fullWidth
                            name="code"
                            label="code"
                            value={code}
                            onChange={e => this.setState({ code: e.target.value })}
                        />
                        {state.isChangePassword ? (
                            <>
                                <TextField
                                    sx={{ mb: 2 }}
                                    margin="normal"
                                    fullWidth
                                    name="password"
                                    label="New Password"
                                    type="password"
                                    value={password}
                                    onChange={e => this.setState({ password: e.target.value })}
                                    helperText={
                                        invalidPassword === true ? "Requires atleast 6 characters" : null
                                    }
                                    error={invalidPassword === true ? true : null}
                                />

                                <TextField
                                    sx={{ mb: 2 }}
                                    margin="normal"
                                    fullWidth
                                    name="confimpassword"
                                    label="Confirm New Password"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={e => this.setState({ confirmPassword: e.target.value })}
                                    helperText={
                                        passwordMismatch === true ? "Passwords do not match" : null
                                    }
                                    error={passwordMismatch === true ? true : null}
                                />
                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 1, mb: 2 }}
                                    onClick={this.changePassword}
                                    loading={requestSubmitted}
                                >
                                    Change Password
                                </LoadingButton>
                            </>

                        ) : (
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1, mb: 2 }}
                                onClick={this.confirmAccount}
                                loading={requestSubmitted}
                            >
                                Confirm Account
                            </LoadingButton>
                        )}

                    </Box>
                </Box>
            </Container>
        );
    };

    render() {
        const { auth } = this.props;
        const { isLoggedIn } = auth;

        return isLoggedIn ? (
            <Redirect to={{ pathname: '/', state: { from: location } }} />
        ) : (
            this.renderLogIn()
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

const cConfirmAccount = connect(mapStateToProps, {
})(ConfirmAccount);


const sConfirmAccount = withSnackbar(cConfirmAccount);
export { sConfirmAccount as ConfirmAccount };
