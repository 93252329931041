import { Container, Box, Breadcrumbs, Link, Typography, Stack, Divider, Button } from '@mui/material';

function Breadcrumb(props) {
    return (
      <Stack justifyContent={"center"} spacing={2} pt={2} >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {props.links.map((l, index) => {
            return (
              <Stack key={index}>
                {l.path ? (
                  <Link underline="hover" color="inherit" href={l.path}>
                    {l.label}
                  </Link>
                ) : (
  
                  <Typography color="text.primary">{l.label}</Typography>
                )}
  
              </Stack>
            )
          })}
        </Breadcrumbs>
        <Divider sx={{ color: "black" }} />
      </Stack>
    );
  }

  export { Breadcrumb }