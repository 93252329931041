import React from "react";
import { connect } from 'react-redux';

import { Carousel } from '../../components/Carousel';
import { PDFSearch } from '../../components/PDFSearch';
import { SearchResults  } from '../../components/SearchResults';
import { withNavbar } from "../../components/withNavbar";

function Home(props) {
  const { homeState } = props;
  
    return (
      <div>
        <PDFSearch />
        {
          homeState.showSearchResults === true ?
          <SearchResults/> : 
          <Carousel />
        }
      </div>
  );
}

const mapStateToProps = state => {
  return {
    homeState: state.home
  };
};

const cHome = connect(mapStateToProps, {
})(Home);

const nHome  = withNavbar(cHome);

export { nHome as Home }; 