import React, { Component } from 'react';

import Amplify, { Auth } from 'aws-amplify';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Container,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  Typography,
  Button,
  Select,
  CssBaseline,
  Box,
  Avatar,
  InputLabel,
  Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { validateEmail, validateMobileNumber, containsCharacters, validatePassword } from '../../../helpers/functions';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      name: "",
      invalidName: false,
      invalidUsername: false,
      invalidPassword: false,
      requestSubmitted: false,
    };
  }

  signUp = async () => {
    try {
      const { password, username, name } = this.state;
      this.setState({
        requestSubmitted: true,
        invalidPassword: false,
        invalidUsername: false,
        invalidName: false,
      });
      if (validateEmail(username)) {
        if (name.length > 0) {
          if (validatePassword(password)) {
            const attributes = {
                email: username,
                name,
            };
             
            const { user } = await Auth.signUp({
              username,
              password,
              attributes,
            });
            if (user) {
              this.setState({ requestSubmitted: false });
              this.props.history.push("confirmaccount", {
                username,
                isChangePassword: false,
              });
            }
          } else {
            this.setState({ requestSubmitted: false, invalidPassword: true });
          }
        } else {
          this.setState({ requestSubmitted: false, invalidName: true });
        }
      } else {
        this.setState({ requestSubmitted: false, invalidUsername: true });
      }
     
    } catch (error) {
      console.log("error signing up", error);
      try {
        let errorMsg = "";
        if (error.code === "UsernameExistsException") {
          errorMsg = "An account already exists with this Email Id";
        } else if (error.code === "CodeDeliveryFailureException") {
          errorMsg = "Unable to send verification code";
        } else if (error.code === "TooManyRequestsException") {
          errorMsg =
            "Too many requests for verification code, Please try again after some time";
        } else {
          throw new Error("Something went wrong");
        }
        this.props.enqueueSnackbar(errorMsg, { variant: 'error' });
      } catch (err) {
        console.log(err);
        this.props.enqueueSnackbar(`Unable to Sign-up`, { variant: 'error' });
      }
      this.setState({ requestSubmitted: false });
    }
  };

  renderLogIn = () => {
    const { username, password, invalidUsername, invalidPassword, requestSubmitted, name, invalidName } = this.state;
  
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              sx={{ mb: 2 }}
              margin="normal"
              fullWidth
              name="Email"
              label="Email"
              value={username}
              onChange={(e) => {
                this.setState({
                  username: e.target.value,
                });
              }}
              helperText={invalidUsername === true ? "Enter a valid Email Id" : null}
              error={invalidUsername}
            />
            <TextField
              sx={{ mb: 3 }}
              margin="normal"
              fullWidth
              name="name"
              label="Name"
              value={name}
              onChange={e => this.setState({ name: e.target.value })}
              helperText={invalidName === true ? "Enter a name" : null}
              error={invalidName === true ? true : null}
            />
            <TextField
              sx={{ mb: 3 }}
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
              helperText={
                invalidPassword === true ? "Requires atleast 6 characters" : null
              }
              error={invalidPassword === true ? true : null}
            />
            <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }} alignItems="center">
              <Typography className="t-title">
                Already have an account?
              </Typography>
              <Button
                variant="text"
                onClick={() => this.props.history.push('/signin')}
              >
                <Typography className="t-title" >
                  Sign In
                </Typography>
              </Button>
            </Stack>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
              onClick={this.signUp}
              loading={requestSubmitted}
            >
              Sign Up
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    );
  };

  render() {
    const { auth } = this.props;
    const { isLoggedIn } = auth;

    return isLoggedIn ? (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    ) : (
      this.renderLogIn()
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const cSignUp = connect(mapStateToProps, {
})(SignUp);


const sSignUp = withSnackbar(cSignUp);
export { sSignUp as SignUp };
