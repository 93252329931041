import React from 'react';
import { Toolbar, Stack, Container, Button, AppBar, Typography, Box, Drawer, Menu, MenuItem, Avatar, Divider, List, ListItem, ListItemText } from '@mui/material';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { signOut } from '../../redux/actions/auth.actions';

function MobileNav(props) {
  let history = useHistory();
  const [drawerState, setDrawerState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState(open);
  };

  return (
    <Box display={{ xs: 'flex', md: 'none' }} >
      <AppBar position="static" sx={{ paddingX: 1 }} >
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Button variant="text" style={{ color: 'white' }} onClick={() => history.push('/')}>
            <Typography variant='h5'>
              Brahma Vidya
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer anchor={"left"} open={drawerState} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: ['70vw', '48vw', '32vw'], height: '100vh' }}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Stack sx={{ backgroundColor: '#1976d2' }}>
            <Button variant="text" style={{ color: 'white' }} onClick={() => history.push('/')} sx={{ marginY: 4, paddingX: 4 }}>
              <Typography variant='h5'>
                Brahma Vidya
              </Typography>
            </Button>
          </Stack>
          <Divider />
          <List>
            <ListItem button onClick={() => history.push('/')} >
              <ListItemText primary={"Home"} />
            </ListItem>
            <ListItem button onClick={() => history.push('/books')} >
              <ListItemText primary={"Books"} />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => history.push('/profile') } >
              <ListItemText primary={"Profile"} />
            </ListItem>
            <ListItem button onClick={() => props.signOut()}>
              <ListItemText primary={"Signout"} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

function Navbar(props) {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { auth } = props;
  const { userAtrributes } = auth
  const { name } = userAtrributes; 

  return (
    <Box>
      <Box display={{ xs: 'none', md: 'flex' }} >
        <AppBar position="static">
          <Toolbar>
            <Container>
              <Stack direction="row" justifyContent="space-between" spacing={1} alignItems="center" sx={{ marginLeft: -1 }} >
                <Stack direction="row" justifyContent="flex-start">
                  <Button variant="text" style={{ color: 'white' }} onClick={() => history.push('/')}>
                    <Typography variant='h5'>
                     Brahma Vidya
                    </Typography>
                  </Button>
                  <Button variant="text" style={{ color: 'white' }} onClick={() => history.push('/')}>
                    <Typography variant='subtitle1'>
                      Home
                    </Typography>
                  </Button>
                  <Button variant="text" style={{ color: 'white' }} onClick={() => history.push('/books')}>
                    <Typography variant='subtitle1'>
                      Books
                    </Typography>
                  </Button>
                </Stack>
                <Stack>
                  <Button
                    style={{ color: 'white' }}
                    // aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar sx={{ bgcolor: '#9c27b0' }}>{name.split(' ').map((n)=> n[0]).join('')}</Avatar>
                      <Typography variant='subtitle1'>
                        {name}
                      </Typography>
                      <ExpandMoreIcon />
                    </Stack>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <MenuItem onClick={() => history.push('/profile')}>Profile</MenuItem>
                    <MenuItem onClick={() => props.signOut()}>Logout</MenuItem>
                  </Menu>
                </Stack>
              </Stack>
            </Container>
          </Toolbar>
        </AppBar>
      </Box>
      <MobileNav {...props} />
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const cNavbar = connect(mapStateToProps, { signOut })(Navbar);


export { cNavbar as Navbar };
