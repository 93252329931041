import Sanscript from '@sanskrit-coders/sanscript';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    TextField, InputLabel, Select,
    Box, FormControl, MenuItem,
    IconButton, InputAdornment, Grid, Container, Stack, ToggleButton, Typography
} from '@mui/material';
import { Search, Translate, Close } from '@mui/icons-material';
import { withRouter } from "react-router";

import books from '../../data/data';
import { showSearchResults, hideSearchResults } from '../../redux/actions/home.actions'

const getSuggestions = (value, language) => {
    return Sanscript.t(value, 'hk', language);
};

class PDFSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            loading: false,
            suggestions: [],
            indexData: undefined,
            key: '',
            error: false,
            language: 'devanagari',
            shouldTranslate: true,
        };
    }


    componentDidMount() {
        const { bookId, language } = this.props;
        let indexData;
        let lan = 'devanagari'
        if (bookId) {
            const book = books.find((book) => book.bookId === bookId);
            indexData = book.indexData;
            lan = book.language;
        } else {
            indexData = books.map((book) => book.indexData);
        }
        var merged = [].concat.apply([], indexData);
        this.setState({ indexData: merged, language: lan });
    }


    onChange = async (event, value) => {
        try {
            this.setState({ value: value });
            let response = getSuggestions(value);
            this.setState({ key: response });
        }
        catch (err) {
            console.log(err)
        }
    };

    onLanguageChange = (event) => {
        this.setState({
            language: event.target.value,
        });
    }

    onChangeText = async (event) => {
        try {
            // this.setState({ value: event.target.value });
            this.setState({ value: event.target.value, loading: true });
            let key = event.target.value;
            if(this.state.shouldTranslate) {
                key = getSuggestions(event.target.value, this.state.language);
            }
            this.setState({ key, loading: false });
        }
        catch (err) {
            console.log(err)
        }
    };

    filter = async (options, state) => options

    onSearch = () => {
        let arr = [];
        this.state.indexData.forEach((item) => {
            if (this.state.key.length > 0) {
                if (item.index.includes(this.state.key)) {
                    arr.push(item)
                }
            }
        });
        this.setState({ suggestions: arr });
        const searchResults = this.sortDataByBook(arr);
        if (arr.length === 0) {
            this.setState({ error: true });
        }
        else {
            this.props.showSearchResults(searchResults);
            this.setState({ error: false });
        }
    }

    distinctCondition = (value, index, self) => {
        return self.indexOf(value) === index
    }

    sortDataByBook = (data) => {
        let distinctBooks = data.map((d) => d.bookId);
        distinctBooks = distinctBooks.filter(this.distinctCondition);
        return distinctBooks.map((b) => {
            const children = data.filter((d) => d.bookId == b);
            return {
                bookId: b,
                children,
            }
        });

    }

    render() {
        return (
            <Container sx={{ paddingTop: 2 }}>
                <Stack sx={{ minHeight: 36 }} mx={1} justifyContent="flex-start" >
                    {this.state.key !== '' ?
                        this.state.key
                        : null
                    }
                </Stack>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" >
                        <Grid item xs={12} sm={12} md={8}>
                            <TextField
                                onChange={this.onChangeText}
                                label="Search"
                                size='medium'
                                color='primary'
                                error={this.state.error}
                                sx={{ width: '100%' }}
                                autoFocus={true}
                                placeholder="Search by book contents"
                                helperText={this.state.error ? "No match found" : ''}
                                value={this.state.value}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {this.state.value !== '' ? (
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({ value: '', key: '', error: false });
                                                        this.props.hideSearchResults(false);
                                                    }}
                                                >
                                                    <Close sx={{ fontSize: 16 }} />
                                                </IconButton>
                                            ) : null}
                                            <IconButton onClick={this.onSearch}>
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onKeyPress={ev => {
                                    if (ev.key === 'Enter') {
                                        this.onSearch();
                                        ev.preventDefault();
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} justifyContent="center">
                            <Stack direction={'row'} spacing={2} justifyContent="center" alignItems="center"  >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{this.state.shouldTranslate === false ? "Translation - turned off": "Translate search value to "}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.language}
                                    label={this.state.shouldTranslate === false ? "Translation - turned off": "Translate search value to "}
                                    onChange={this.onLanguageChange}
                                    disabled={!this.state.shouldTranslate}
                                    fullWidth
                                >
                                    <MenuItem value="devanagari">Sanskrit</MenuItem>
                                    <MenuItem value="telugu">Telugu</MenuItem>
                                    <MenuItem value="kannada">Kannada</MenuItem>
                                    <MenuItem value="tamil">Tamil</MenuItem>
                                    <MenuItem value="english">English</MenuItem>
                                </Select>
                                
                            </FormControl>
                            <ToggleButton
                                value="check"
                                selected={this.state.shouldTranslate}
                                onChange={() => {
                                    this.setState((prev) => {
                                        const language = !prev.shouldTranslate ? 'devanagari' : '';
                                        return {
                                            ...prev,
                                            shouldTranslate: !prev.shouldTranslate,
                                            language,
                                        }
                                    })
                                }}
                            >
                                <Translate />
                                <Typography>{`${this.state.shouldTranslate ? 'On': 'Off'}`}</Typography>
                            </ToggleButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        homeState: state.home
    };
};


const pdfSearchContainer = connect(mapStateToProps, {
    showSearchResults, hideSearchResults
})(PDFSearch);

const pdfSerachRouter = withRouter(pdfSearchContainer);
export { pdfSerachRouter as PDFSearch };