export const validateEmail = (email) => {
    const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(email).toLowerCase());
};

export const validateMobileNumber = (text) => {
    const reg = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
    return reg.test(text);
};

export const validatePassword = (password) => {
    // const reg =
    //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/;
    // return reg.test(password);
    return password && password.length >= 6;
};

export const containsCharacters = (str) => {
    const reg = /[a-zA-Z]/g;
    return reg.test(str);
};
