import React from "react";
import { ThemeProvider } from '@mui/material/styles';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import Amplify from "aws-amplify";
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';

import { store, persistor } from './redux/store';
import theme from './helpers/theme';
import { Main } from './main';
import { awsconfig } from './helpers/config/aws-config'

Amplify.configure(awsconfig);

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
      <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            action={key => {
              const { closeSnackbar } = useSnackbar();

              return (
                <IconButton
                  onClick={() => {
                    closeSnackbar(key);
                  }}
                >
                  <CloseOutlined sx={{ color: '#fff', fontSize: 14 }} />
                </IconButton>
              );
            }}
          >
        <Main />
        </SnackbarProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export { App };