import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Paper, Container, Grid, Stack, Box, Typography } from '@mui/material';
import books from '../../data/data';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

function Summary(props) {
    return (
        <>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {
                    props.summaryData.map((summary) => {
                        return (
                            <Grid item key={summary.id} xs={12} sm={12} md={6} sx={{ borderRadius: 4 }}>
                                <Paper elevation={4}>
                                    <Link to={`/books/${props.bookId}/${summary.startPage}`} variant="outline-light" >
                                        <img variant="top" src={summary.snippet} style={{ width: '100%', height: '350px' }} />
                                    </Link>
                                </Paper>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Divider sx={{ padding: 2, color: "black" }} />
        </>
    )
}

function CustomCarousel() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [hoveredBook, setHoveredBook] = React.useState({});
    const handleClick = (event, book) => {
        setAnchorEl(event.currentTarget);
        setHoveredBook(book);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Container>
            {
                books.map((book) => {
                    return (
                        <Box key={book.bookId} sx={{ marginTop: 8 }}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} my={3} spacing={3} alignItems="center">
                                <img src={book.bookCover} style={{ width: 75, height: 75 }} onClick={(event) => handleClick(event, book)} />
                                <Stack spacing={1} alignItems={{ xs: 'center', sm: 'flex-start' }}>
                                    <Typography variant="h4" align="center" >{book.bookName}</Typography>
                                    <Chip variant="outlined" style={{ textTransform: 'capitalize' }} label={book.languageLabel} />

                                </Stack>
                            </Stack>
                            <Summary summaryData={book.summaryData} bookId={book.bookId} />
                        </Box>
                    )
                })
            }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <img src={hoveredBook.bookCover} style={{ width: 320, height: 380 }} />
            </Popover>
        </Container>
    );
}

function CustomPoper(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

}

const mapStateToProps = state => {
    return {
        global: state.global,
    };
};

const customCarouselContainer = connect(mapStateToProps, {
})(CustomCarousel);

export { customCarouselContainer as Carousel };