import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { connect } from 'react-redux';

import { Books } from './pages/Books';
import { Home } from './pages/Home';
import { NoPageFound } from './components/NoPageFound';
import { PrivateRoute } from './components/PrivateRoute';
import { SignIn  } from './pages/Auth/SignIn'; 
import { SignUp  } from './pages/Auth/SignUp'; 
import { ForgotPassword  } from './pages/Auth/ForgotPassword'; 
import { ConfirmAccount  } from './pages/Auth/ConfirmAccount'; 
import { Profile  } from './pages/Profile'; 

function Main(params) {
    return (
        <Router>
                <Switch>
                    <Route exact path="/signin" component={SignIn} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route exact path="/forgotpassword" component={ForgotPassword} />
                    <Route exact path="/confirmaccount" component={ConfirmAccount} />
                    <PrivateRoute path="/books" component={Books} />
                    <PrivateRoute path="/" exact component={Home} />
                    <PrivateRoute path="/profile" exact component={Profile} />
                    <Route path="*" component={NoPageFound}>
                    </Route>
                </Switch>
        </Router>
    );
}

const mapStateToProps = state => {
    return {
        global: state.global,
    };
};

const mainContainer = connect(mapStateToProps, {
})(Main);

export { mainContainer as Main };