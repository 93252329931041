import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import { connect } from 'react-redux';
import { Container, Box, Breadcrumbs, Link, Typography, Stack, Divider, Button } from '@mui/material';
import { Launch } from '@mui/icons-material';

import { PDFView } from "../../components/PDFView";
import { NoPageFound } from "../../components/NoPageFound";
import { Table } from "../../components/Table";
import books from '../../data/data';
import { withNavbar } from "../../components/withNavbar";
import { Breadcrumb } from "../../components/Breadcrumbs";


function Book(props) {
  const params = useParams();
  const { bookId } = params;
  const book = books.find((b) => b.bookId === bookId);
  const breadcrumbLinks = [{ label: "Home", path: "/" }, { label: "Books", path: "/books" }, { label: book ? book.bookName : 'No book' }];

  return (
    <Box>
      <Breadcrumb links={breadcrumbLinks} />
      {book ?
        <Box sx={{ paddingTop: 2 }}>
          <PDFView />
        </Box> : <NoPageFound />}
    </Box>
  );
}

function Books(props) {
  const breadcrumbLinks = [{ label: "Home", path: "/" }, { label: "Books" }];
  const history = useHistory();

  const onOpen = (value) => {
    history.push(`/books/${value}`)
  }

  const columnsConfig = [
    {
      field: 'col1',
      headerName: 'Book',
      key: 'bookCover',
      width: 120,
      renderCell: (params) => (
        <img src={params.value} style={{ width: 88, height: 88 }} />
      ),
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'col2',
      headerName: 'Name',
      key: 'bookName',
      minWidth: 160,
      flex: 1
    },
    {
      field: 'col3',
      headerName: 'Language',
      key: 'languageLabel',
      width: 160,
    },
    {
      field: 'col4',
      headerName: 'View',
      key: 'bookId',
      width: 160,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          endIcon={<Launch />}
          onClick={() => onOpen(params.value)}
        >
          Open
        </Button>
      ),
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Box>
      <Breadcrumb links={breadcrumbLinks} />
      <Box sx={{ marginY: 3 }}>
        <Table columnsConfig={columnsConfig} data={books} />

      </Box>
    </Box>
  );
}

function BooksContainer(props) {
  let match = useRouteMatch();
  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/:bookId/:page`}>
          <Book />
        </Route>
        <Route path={`${match.path}/:bookId`}>
          <Book />
        </Route>
        <Route path="*">
          <Books />
        </Route>
      </Switch>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    global: state.global,
  };
};

const cBooks = connect(mapStateToProps, {
})(BooksContainer);

const nBooks  = withNavbar(cBooks);

export { nBooks as Books }; 