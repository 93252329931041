import React, { useState, useEffect } from "react";

import { Container, Box, Stack, Avatar, Typography, Divider, TextField, Button, Switch, Dialog, DialogTitle } from "@mui/material";
import Amplify, { Auth } from "aws-amplify";
import { connect } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { withSnackbar } from 'notistack';

import { withNavbar } from "../../components/withNavbar";
import { Breadcrumb } from "../../components/Breadcrumbs";
import { validateEmail, validatePassword } from "../../helpers/functions";
import { signOut, updateAttributes } from '../../redux/actions/auth.actions';

const breadcrumbLinks = [{ label: "Home", path: "/" }, { label: "Profile" }];

function Profile(props) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [invalidUsername, setInvalidUsername] = useState(false);
    const [invalidName, setInvalidName] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalidNewPassword, setInvalidNewPassword] = useState(false);
    const [showPasswordDialog, togglePasswordDialog] = useState(false);
    const [showDelAccountDialog, toggleDelAccountDialog] = useState(false);
    const [displayName, setDisplayName] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");


    useEffect(async () => {
        setAttributes();
    }, []);

    const setAttributes = () => {
        const { auth } = props;
        const { userAtrributes } = auth;
        const { name, username } = userAtrributes;
        setEmail(username);
        setName(name);
        setDisplayName(name);
    }

    const updateAttributes = async () => {
        try {
            setRequestSubmitted(true);
            setInvalidUsername(false);
            setInvalidName(false);
            if (name.length > 0) {
                if (validateEmail(email)) {
                    const user = await Auth.currentAuthenticatedUser();
                    const result = await Auth.updateUserAttributes(user, {
                        'email': email,
                        'name': name,
                    });
                    props.enqueueSnackbar("Update Successful!", { variant: 'success' });
                    props.updateAttributes({
                        username: email,
                        name: name
                    });
                    setDisplayName(name);
                } else {
                    setInvalidUsername(true);
                }
            } else {
                setInvalidName(true)
            }
            setRequestSubmitted(false);
        } catch (err) {
            setRequestSubmitted(false);
            props.enqueueSnackbar("Update Failed!", { variant: 'error' });
            console.log(err);
        }
    }

    const signUserOut = async () => {
        try {
            props.signOut();
            await Auth.signOut({ global: true });
        } catch (err) {
            props.signOut();
            console.log(err);
        }
    }

    const changePassword = async () => {
        try {
            setRequestSubmitted(true);
            setInvalidPassword(false);
            setInvalidNewPassword(false);
            if (validatePassword(password)) {
                if (validatePassword(newPassword)) {
                    if (password !== newPassword) {
                        const user = await Auth.currentAuthenticatedUser();
                        const result = await Auth.changePassword(user, password, newPassword);
                        if (result) {
                            props.enqueueSnackbar("Password Update Successful", { variant: 'success' });
                            togglePasswordDialog(false);
                        } else {
                            throw new Error("Unable to update password");
                        }
                    } else {
                        props.enqueueSnackbar("Please provide a password which is different from previous", { variant: 'error' });
                    }
                } else {
                    setInvalidNewPassword(true);
                }
            } else {
                setInvalidPassword(true);
            }
            setRequestSubmitted(false);
        } catch (error) {
            console.log(error);
            setRequestSubmitted(false);
            try {
                if (error.code === "NotAuthorizedException") {
                    props.enqueueSnackbar(`Incorrect Password`, { variant: 'error' });
                } else  if (error.code === "LimitExceededException") {
                    props.enqueueSnackbar(`Attempt limit exceeded, please try after some time`, { variant: 'error' });
                } else {
                    throw new Error("Something went wrong");
                }
            } catch (err) {
                props.enqueueSnackbar("Password Update Failed", { variant: 'error' });
            }
        }
    };

    const deleteUser = async () => {
        return new Promise(async (res, rej) => {
            const user = await Auth.currentAuthenticatedUser();
            user.deleteUser((error, data) => {
                if (error) {
                    rej(error)
                }
                res();
            });
        });
    }

    const deleteAccount = async () => {
        try {
            setRequestSubmitted(true);
            await deleteUser();
            props.enqueueSnackbar("Your account has been deleted!", { variant: 'success' });
            props.signOut();
            setRequestSubmitted(false);
        } catch (error) {
            console.log(error)
            props.enqueueSnackbar("Something went wrong!", { variant: 'error' });
            setRequestSubmitted(false);
        }
    }

    return (
        <Container>
            <Breadcrumb links={breadcrumbLinks} />
            <Stack direction={{ xs: 'column', md: 'row' }} divider={<Divider orientation="vertical" flexItem />} sx={{ marginTop: 2 }}>
                <Stack sx={{ marginX: 6, marginY: { xs: 2, md: 2 } }} flex={1}>
                    <Stack spacing={1} alignItems="center" sx={{ padding: 4 }}>
                        <Avatar sx={{ bgcolor: '#9c27b0', width: 108, height: 108, fontSize: 48 }}>{displayName.split(' ').map((n) => n[0]).join('')}</Avatar>
                        <Typography variant='h5'>
                            {displayName}
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <Button variant="outlined" onClick={signUserOut} >Sign Out</Button>
                        <Divider />
                        <Stack spacing={1}>

                            <Button variant="outlined" onClick={() => togglePasswordDialog(true)} >Change Password</Button>
                            <Button variant="outlined" color="warning" onClick={() => toggleDelAccountDialog(true)}>Delete Account</Button>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack sx={{ margin: 2, paddingX: 4 }} flex={2}>
                    <Typography variant="h5">Profile</Typography>
                    <Divider />
                    <Stack my={4}>
                        <TextField
                            sx={{ mb: 2 }}
                            name="Name"
                            label="Name"
                            value={name}
                            type="text"
                            fullWidth
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                            helperText={invalidName === true ? "Enter a name" : null}
                            error={invalidName === true ? true : null}
                        />
                        <TextField
                            sx={{ mb: 2 }}
                            name="Email"
                            label="Email"
                            type="text"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            helperText={invalidUsername === true ? "Enter a valid Email Id" : null}
                            error={invalidUsername}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" my={2} >
                        <LoadingButton
                            variant="contained"
                            loading={requestSubmitted}
                            onClick={updateAttributes}
                            disabled={props.auth.userAtrributes.username === email && props.auth.userAtrributes.name === name}
                        >
                            Save
                        </LoadingButton>
                        <Button variant="outlined" onClick={setAttributes}>Cancel</Button>
                    </Stack>
                </Stack>
            </Stack>
            <Dialog onClose={() => togglePasswordDialog(false)} open={showPasswordDialog}>
                <DialogTitle>Change Password</DialogTitle>
                <Divider />
                <Stack sx={{ marginX: 12, marginY: 2 }}>
                    <TextField
                        sx={{ mb: 2 }}
                        name="password"
                        label="Current Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        helperText={
                            invalidPassword === true ? "Requires atleast 6 characters" : null
                        }
                        error={invalidPassword === true ? true : null}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        name="password"
                        label="New Password"
                        type="password"
                        fullWidth
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                        helperText={
                            invalidNewPassword === true ? "Requires atleast 6 characters" : null
                        }
                        error={invalidNewPassword === true ? true : null}
                    />
                </Stack>
                <Divider />
                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ margin: 2 }} >
                    <LoadingButton
                        variant="contained"
                        disabled={password.length === 0 || newPassword.length === 0}
                        loading={requestSubmitted}
                        onClick={changePassword}
                    >
                        Save
                    </LoadingButton>
                    <Button variant="outlined" onClick={() => togglePasswordDialog(false)}>Cancel</Button>
                </Stack>

            </Dialog>
            <Dialog onClose={() => toggleDelAccountDialog(false)} open={showDelAccountDialog}>
                <DialogTitle>Delete Account</DialogTitle>
                <Divider />
                <Stack sx={{ marginX: 12, marginY: 2 }}>
                        Are you sure you want to delete your account?
                    <Typography>
                    </Typography>
                </Stack>
                <Divider />
                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ margin: 2 }} >
                    <LoadingButton
                        variant="contained"
                        color="error"
                        onClick={deleteAccount}
                    >
                        Delete
                    </LoadingButton>
                    <Button variant="outlined" onClick={() => toggleDelAccountDialog(false)}>Cancel</Button>
                </Stack>
            </Dialog>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

const cProfile = connect(mapStateToProps, {
    updateAttributes, signOut
})(Profile);

const nProfile = withNavbar(cProfile);

const sProfile = withSnackbar(nProfile);

export { sProfile as Profile }; 