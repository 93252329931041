import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Container, Stack, IconButton, Slider, Box, Paper, Typography, Button, Divider, List, ListItem, Drawer, ListItemText, CircularProgress, Dialog } from '@mui/material';
import { ZoomIn, ZoomOut, ChevronRight, ChevronLeft, Launch } from '@mui/icons-material';
import {
  useParams
} from "react-router-dom";


import books from '../../data/data';

export function BookIndex(props) {
  const [drawerState, setDrawerState] = React.useState(false);
  const params = useParams();
  const currentBook = books.find((b) => b.bookId == params.bookId)
  const listItems = currentBook.indexData;

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState(open);
  };

  const onItemClick = (value) => (event) => {
    event.preventDefault();
    props.onClick(parseInt(value));
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={toggleDrawer(true)} endIcon={<Launch />}>Contents</Button>
      <Drawer anchor={"right"} open={drawerState} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: ['70vw', '48vw', '32vw'], height: '100vh' }}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Stack alignItems="center" sx={{ marginY: 2 }}>
            <img src={currentBook.bookCover} style={{ width: 75, height: 75 }} />
            <h2 className="text-left px-4">{currentBook.bookName}</h2>
          </Stack>
          <Divider />
          <List>
            {listItems.map((item) => (
              <ListItem button key={item.index} onClick={onItemClick(item.startPage)} >
                <ListItemText primary={item.index} key={item.index} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

function PDFView(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const [book, setBook] = useState({});
  const params = useParams();

  useEffect(() => {
    if (dataLoaded === false) {
      const book = books.find((b) => b.bookId === params.bookId);
      if (params.page) {
        setPageNumber(parseInt(params.page));
      } else {
        setPageNumber(1);
      }
      setBook(book);
      setDataLoaded(true);
    }
  });

  const handleChange = (event, value) => {
    setPageNumber(value);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // props.setNumPages(numPages);
    setPdfLoading(false)
  }

  const onScaleUp = () => {
    if (scale + 1 <= 4) {
      setScale(scale + 1);
    }
  }

  const onScaleDown = () => {
    if (scale - 1 >= 1) {
      setScale(scale - 1);
    }
  }

  const onNext = () => {
    if (pageNumber + 1 <= numPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  const onBack = () => {
    if (pageNumber - 1 >= 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  const hidePdfLoader = () => {
    setPdfLoading(false);
  }

  return (
    <>
      <Paper elevation={3} >
        <Box sx={{ height: '75vh', overflow: 'scroll' }}>
          <Stack justifyContent={{ sm: 'flex-start', md: scale <= 2 ? "center" : "flex-start" }}
            alignItems={{ sm: 'flex-start', md: scale <= 2 ? "center" : "flex-start" }}>

            <Document
              file={book.bookPath}
              loading={"Loading"}
              error={"Something went wrong!"}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={hidePdfLoader}
            >
              <Page renderMode="canvas" onRenderSuccess={hidePdfLoader} onRenderError={hidePdfLoader} scale={scale} pageNumber={pageNumber} />
            </Document>
          </Stack>
        </Box>
        <Stack direction={{ sm: 'row', md: 'row' }} alignItems="center" justifyContent="center" spacing={1} sx={{ marginX: 2 }}>
          <Stack direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent="center" spacing={1} display={{ xs: 'none', md: 'flex' }}>
            <Slider
              value={pageNumber}
              valueLabelDisplay="auto"
              step={1}
              min={1}
              max={numPages}
              onChange={(e, v) => { setPageNumber(v) }}
            // valueLabelDisplay="on"
            />
            <Typography variant="subtitle2" style={{ minWidth: 96 }} >{`(${pageNumber} of ${numPages})`}</Typography>
          </Stack>
          <IconButton onClick={onBack} disabled={pageNumber === 1}>
            <ChevronLeft />
          </IconButton>
          <IconButton onClick={onNext} disabled={pageNumber === numPages}>
            <ChevronRight />
          </IconButton>
          <IconButton onClick={onScaleDown}>
            <ZoomOut />
          </IconButton>
          <IconButton onClick={onScaleUp}>
            <ZoomIn />
          </IconButton>
          <BookIndex bookId={book.bookId} onClick={setPageNumber} />
        </Stack>
      </Paper>
      <Dialog onClose={() => { }}  open={pdfLoading === true}>
        <Stack justifyContent="center" alignItems="center" sx={{ padding: 2 }}>
          <CircularProgress />
          <Typography>Loading...</Typography>
        </Stack>
      </Dialog>
    </>
  );
}

const mapStateToProps = state => {
  return {
    global: state.global,
  };
};

const pdfViewContainer = connect(mapStateToProps, {
})(PDFView);

export { pdfViewContainer as PDFView };