// imports
import React from 'react';
import { Box } from '@mui/material';
import { connect } from 'react-redux';

import { Navbar } from '../Navbar';

function withNavbar(WrappedComponent) {
    return class extends React.Component {
        render() {
            return (
                <Box>
                    <Navbar />
                    <div style={{ backgroundColor: '#F7F7F7', height: 'auto', minHeight: '100vh' }}>
                        <WrappedComponent {...this.props} />;
                    </div>
                </Box>
            );
        }
    };
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

const cwithNavbar = connect(mapStateToProps, {})(withNavbar);
export { withNavbar as withNavbar }
