import { SHOW_SEARCH_RESULTS, HIDE_SEARCH_RESULTS } from '../action.types';

export const hideSearchResults = () => ({
    type: HIDE_SEARCH_RESULTS,
})

export const showSearchResults = (value) => ({
    type: SHOW_SEARCH_RESULTS,
    payload: value,
});
