import { SHOW_SEARCH_RESULTS, HIDE_SEARCH_RESULTS } from '../action.types';
const INITIAL_STATE = {
    showSearchResults: false,
    searchResults: [],
};

const homeReducer =  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_SEARCH_RESULTS: 
        return {
            ...state,
            showSearchResults: true,
            searchResults: action.payload,
        }
    case HIDE_SEARCH_RESULTS:
        return {
            ...state,
            showSearchResults: false,
        }
    default:
        return state;
  }
};

export default homeReducer;
