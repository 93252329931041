import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';

import reducers from './reducers';

const persistConfig = {
  key: 'brahmavidya',
  whitelist: 'auth',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  {},
  compose(
    applyMiddleware(ReduxThunk),
    // eslint-disable-next-line no-underscore-dangle, no-undef
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
);

const persistor = persistStore(store);

export { store, persistor };
