// imports
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends Component {
  render() {
    const { component: PComponent, ...rest } = this.props;
    const { isLoggedIn } = this.props.auth;
    return (
      <Route
        {...rest}
        render={props =>
            isLoggedIn ? (
            <PComponent {...props} />
          ) : (
            <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
          )
        }
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const cPrivateRoute = connect(mapStateToProps, {})(PrivateRoute);
export { cPrivateRoute as PrivateRoute }
