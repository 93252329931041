import React, { Component } from 'react';

import Amplify, { Auth, Hub } from 'aws-amplify';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Container,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  Typography,
  Button,
  Select,
  CssBaseline,
  Box,
  Avatar,
  InputLabel,
  Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Google from '@mui/icons-material/Google';

import { signIn } from '../../../redux/actions/auth.actions';
import { validateEmail, validateMobileNumber, containsCharacters } from '../../../helpers/functions';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      requestSubmitted: false,
      invalidUsername: false,
      invalidPassword: false,
      hostedUIRequestSubmitted: false,
    };
  }

  componentDidMount() {
    Hub.listen("auth", this.hubListener);
  }

  componentWillUnmount() {
    Hub.remove("auth", this.hubListener);
  }

  hubListener = async ({ payload: { event, data } }) => {
    switch (event) {
      case "cognitoHostedUI":
        await this.hostedUISignIn();
        break;
      case "cognitoHostedUI_failure":
        console.log("Sign in failure", data);
        break;
      default:
        break;
    }
  };

  signIn = async () => {
    try {
      const { username, password } = this.state;
      this.setState({
        requestSubmitted: true,
        invalidUsername: false,
        invalidPassword: false, 
      });
      if (validateEmail(username)) {
        if(password.length > 0 ) {

          const user = await Auth.signIn(username, password);
          this.props.signIn(user);
        } else {
          this.setState({ requestSubmitted: false, invalidPassword: true });
        }
      } else {
        this.setState({ requestSubmitted: false, invalidUsername: true });
      }
    }
    catch (error) {
      console.log("error signing in", error);
      try {
        if (error.code === "UserNotConfirmedException") {
          await Auth.resendSignUp(username);
          this.props.history.push("confirmaccount", {
            username,
            isChangePassword: false,
          });
        }
        else if (error.code === "NotAuthorizedException") {
          this.props.enqueueSnackbar(`Incorrect Username or Password`, { variant: 'error' });
        } else {
          throw new Error("Something went wrong");
        }
      } catch (err) {
        this.props.enqueueSnackbar(`Unable to Sign-in`, { variant: 'error' });
      }
      this.setState({ requestSubmitted: false });
    }
  };

  hostedUISignIn = async () => {
    try {
      this.setState({
        hostedUIRequestSubmitted: true,
      });
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        this.props.signIn(user);
      }
    } catch (error) {
      console.log("error signing in", error);
      this.props.enqueueSnackbar(`Unable to Sign-in`, { variant: 'error' });
      this.setState({
        hostedUIRequestSubmitted: false,
      });
    }
  };

  renderLogIn = () => {
    const { username, password, invalidUsername, requestSubmitted, hostedUIRequestSubmitted, invalidPassword } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              sx={{ mb: 2 }}
              margin="normal"
              fullWidth
              name="Email"
              label="Email"
              value={username}
              onChange={(e) => {
                this.setState({
                  username: e.target.value,
                });
              }}
              helperText={invalidUsername === true ? "Enter a valid Email Id ": null}
              error={invalidUsername}
            />
            <TextField
              sx={{ mb: 3 }}
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
              helperText={
                invalidPassword === true ? "Enter the password" : null
              }
              error={invalidPassword === true ? true : null}
            />
            <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }} alignItems="center">
              <Button
                variant="text"
                onClick={() => this.props.history.push('/signup')}
              >
                <Typography >
                  Sign Up
                </Typography>
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  this.props.history.push('/forgotpassword');
                }}
              >
                <Typography>
                  Forgot Password?
                </Typography>
              </Button>
            </Stack>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
              onClick={this.signIn}
              // onClick={() => this.props.signIn()}
              loading={requestSubmitted}
            >
              Sign In
            </LoadingButton>
          </Box>
          <LoadingButton
              fullWidth
              variant="outlined"
              sx={{ mt: 1, mb: 2 }}
              onClick={() => Auth.federatedSignIn( {provider: 'Google'} )}
              loading={hostedUIRequestSubmitted}
              startIcon={<Google />}
              color="primary"
            >
              Continue with Google
            </LoadingButton>
        </Box>
      </Container>
    );
  };

  render() {
    const { auth, location } = this.props;
    const { isLoggedIn } = auth;

    return isLoggedIn ? (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    ) : (
      this.renderLogIn()
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const cSignIn = connect(mapStateToProps, {
  signIn,
})(SignIn);

const sSignIn = withSnackbar(cSignIn);
export { sSignIn as SignIn };
