import Sanscript from '@sanskrit-coders/sanscript';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Stack, Typography, Select,
    Box, FormControl, MenuItem,
    IconButton, InputAdornment, Chip,
    Divider, Container
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { withRouter } from "react-router";

import { hideSearchResults } from '../../redux/actions/home.actions';
import books from '../../data/data';

class SearchResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }

    onSubmit = (book) => {
        if(this.props.bookId) {
            this.props.onSearch(book.startPage);
        } else {
            this.props.history.push(`/books/${book.bookId}/${book.startPage}`);
        }
    }

    render() {
        const { homeState } = this.props;

        return (
            <Container>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ marginY: 4 }}>
                    <Chip
                        label="Content"
                        color={"default"}
                    />
                    <Chip
                        label="Preface"
                        color={"primary"}
                    />
                     <IconButton onClick={() => this.props.hideSearchResults()}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                {
                    homeState.searchResults.map((a) => {
                        const currentBook = books.find((b) => b.bookId == a.bookId)
                        const keys = a.children.map((c, index)=> {
                            return (
                                <Chip
                                    label={c.index}
                                    clickable
                                    color={c.isPreface === true ? "primary": "default"}
                                    onClick={() => this.onSubmit(c)} key={c.id}
                                    style={{ margin: 5 }}
                                    key={index}
                                />
                            )
                        })
                        
                        return (
                            <Box key={currentBook.bookId}> 
                                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2} sx={{ marginY: 2 }}>
                                    <img src={currentBook.bookCover} style={{ width: 75, height: 75 }}  />
                                    <Typography variant="h4" align="center" >{currentBook.bookName}</Typography>
                                </Stack>
                                <Divider />
                                <Stack alignItems="flex-start" sx={{ marginY: 4 }}>
                                {keys}
                                </Stack>
                            </Box>
                            
                        )
                    })
                }
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        global: state.global,
        homeState: state.home
    };
};



const searchResultsContainer = connect(mapStateToProps, { hideSearchResults
})(SearchResults);

const SearchResultsRouter = withRouter(searchResultsContainer);
export { SearchResultsRouter as SearchResults };