import React, { Component } from 'react';
import { Stack, Tooltip } from '@mui/material';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            columns: [],
        };
    }

    async componentDidMount() {
        const { data, columnsConfig } = this.props;
        let rows = [];

        rows = data.map((o, index) => {
            let row = { id: index };

            columnsConfig.forEach(k => {
                row = {
                    ...row,
                    [k.field]: o[k.key],
                };
            });
            return row;
        });
        this.setState({
            rows,
            columns: columnsConfig,
        });
    }

    CustomToolbar = () => {
        return (
            <Stack direction="row" justifyContent="flex-end">
                <Tooltip title="Export">
                    <GridToolbarExport classes={{ root: 'Custom-MuiButton-root' }} />
                </Tooltip>
            </Stack>
        );
    };

    render() {
        const { columns, rows } = this.state;
        const {
            showExport = false,
            checkboxSelection = false,
            isRowSelectable = () => true,
            selectionModel = [],
            onSelectionModelChange = () => { },
        } = this.props;

        return (
            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                checkboxSelection={checkboxSelection}
                isRowSelectable={isRowSelectable}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectionModel}
                rowHeight={100}
                components={
                    showExport
                        ? {
                            Toolbar: this.CustomToolbar,
                        }
                        : {}
                }
                pageSize={10}
                pagination
            />
        );
    }
}

export { Table };
