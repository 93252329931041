import { LOGIN_SUCCESS, RESET_AUTH_STATE, UPDATE_ATTRIBUTES } from '../action.types';

export const resetAuthState = () => ({
    type: RESET_AUTH_STATE,
})

export const signIn = (user) => ({
    type: LOGIN_SUCCESS,
    payload: {
        username: user.attributes.email,
        name: user.attributes.name,
    },
});

export const signOut = () => ({
    type: RESET_AUTH_STATE,
});

export const updateAttributes = (data) => ({
    type: UPDATE_ATTRIBUTES,
    payload: data
});
