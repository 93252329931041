// Global
export const TOGGLE_LOADER = 'TOGGLE_LOADER';


// Home
export const SHOW_SEARCH_RESULTS = 'SHOW_SEARCH_RESULTS';
export const HIDE_SEARCH_RESULTS = 'HIDE_SEARCH_RESULTS';


// Login 
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const RESET_AUTH_STATE = 'RESET_AUTH_STATE';
export const UPDATE_ATTRIBUTES = 'UPDATE_ATTRIBUTES';