import React from "react";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";

function NoPageFound(props) {
  let history = useHistory();
  const backClick = () => {
    history.push(`/`);
  }
  return (
    <div className="d-flex justify-content-center align-items-center flex-column p-5">
      <h3>No Page found</h3>
      <Button variant="contained" color="primary" onClick={backClick} >Go back to Home</Button>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    global: state.global,
  };
};

const noPageFoundContainer = connect(mapStateToProps, {
})(NoPageFound);

export { noPageFoundContainer as NoPageFound }; 