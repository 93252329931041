import { combineReducers } from 'redux';

import GlobalReducer from './global.reducer';
import HomeReducer from './home.reducer';
import AuthReducer from './auth.reducer';

export default combineReducers({
  global: GlobalReducer,
  home: HomeReducer,
  auth: AuthReducer,
});
