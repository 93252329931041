import { TOGGLE_LOADER } from '../action.types';
const INITIAL_STATE = {
  loader: false
};

const globalReducer =  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LOADER: 
      return {
        ...state,
        loader: action.paylaod,
      }
    default:
      return state;
  }
};

export default globalReducer;
