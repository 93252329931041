import React, { Component } from 'react';

import Amplify, { Auth } from 'aws-amplify';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Container,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  Typography,
  Button,
  Select,
  CssBaseline,
  Box,
  Avatar,
  InputLabel,
  Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { validateEmail, validateMobileNumber, containsCharacters, validatePassword } from '../../../helpers/functions';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
        username: "",
        invalidUsername: false,
        requestSubmitted: false,
    };
  }

  resetPassword = async () => {
    try {
      const { username } = this.state;
      this.setState({
        requestSubmitted: true,
        invalidUsername: false,
      });
      if (validateEmail(username)) {
        await Auth.forgotPassword(username);
        this.setState({ requestSubmitted: false });
        this.props.history.push("confirmaccount", {
          username,
          isChangePassword: true,
        });
      } else {
        this.setState({
          requestSubmitted: false,
          invalidUsername: true,
        });
      }
    } catch (error) {
      console.log("error sending code", error);
      try {
        let errorMsg = "";
        if (error.code === "CodeDeliveryFailureException") {
          errorMsg = "Unable to send verification code";
        } else if (error.code === "TooManyRequestsException") {
          errorMsg =
            "Too many requests for verification code, Please try again after some time";
        } else {
          throw new Error("Something went wrong");
        }
        this.props.enqueueSnackbar(errorMsg, { variant: 'error' });
      } catch (err) {
        this.props.enqueueSnackbar("Unable to recover your password", { variant: 'error' });
      }
      this.setState({
        requestSubmitted: false,
      });
    }
  };

  renderLogIn = () => {
    const { username, invalidUsername, requestSubmitted } = this.state;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          Forgot Password
          </Typography>
          <Box sx={{ mt: 1 }}>
          <Typography sx={{ mt: 2, mb: 2 }} textAlign="center">
              Enter your Email Id to reset the password
            </Typography>
            <TextField
              sx={{ mb: 2 }}
              margin="normal"
              fullWidth
              name="Email"
              label="Email"
              value={username}
              onChange={(e) => {
                this.setState({
                  username: e.target.value,
                });
              }}
              helperText={invalidUsername === true ? "Enter a valid Email Id ": null}
              error={invalidUsername}
            />
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
              onClick={this.resetPassword}
              loading={requestSubmitted}
            >
              Reset My Password
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    );
  };

  render() {
    const { auth } = this.props;
    const { isLoggedIn } = auth;

    return isLoggedIn ? (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    ) : (
      this.renderLogIn()
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const cForgotPassword = connect(mapStateToProps, {
})(ForgotPassword);


const sForgotPassword = withSnackbar(cForgotPassword);
export { sForgotPassword as ForgotPassword };
